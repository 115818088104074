import React from 'react';

function Home() {
  
  return (
    <section>
      <h2>RCCG True Vine Parish.</h2>
      <p>A very warm welcome to the website of the True Vine Parish,. It's with great delight to be a part of The Redeemed Christian Church of God (RCCG) Worldwide which has over 6000 parishes around the world and over 3 million members. We are a friendly and welcoming church where everyone is treated as family.</p>
      <p>Our membership is diverse and our doors are always open to embrace new members. Come and experience transformation through a life of worship, prayer, power and service at the RCCG True Vine Parish.</p>
  </section>  
  )
}

export default Home;