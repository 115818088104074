import React from 'react';
import { Route, Switch } from 'react-router-dom';
import About from './About';
import Home from './Home';
import PastorsCorners from './PastorsCorner';
import ContactUs from './ContactUs';
import LandingPage from './LandingPage';
import Services from './Services';
import Annoucements from './Annoucements';
import Departments from './Departments';

function Routes() {
  
  return (
    <>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/about-us" component={About} />
        <Route exact path="/pastors-corner" component={PastorsCorners} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/announcements" component={Annoucements} />
        <Route exact path="/departments" component={Departments} />
      </Switch>
    </>
  )
}
export default Routes;