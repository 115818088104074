import React from "react";
import PageContent from "./PageContent";
import "./PastorCorner.css";

function PastorsCorners() {
  return (
    <PageContent pageTitle="Pastor's Corner" bgImage="pastors-corner">
      <section className="PastorCorner">
        <h3 className="PastorCorner-title">VICTORIOUS RACE</h3>
        <p>
          Lamentation 3:22-23 It is of the LORD'S mercies that we are not
          consumed, because his compassions fail not. 23 They are new every
          morning: great is thy faithfulness. All glory be to God in the highest
          for the privilege to be alive 1 Jn 5:4 For whatsoever is born of God
          overcometh the world: and this is the victory that overcometh the
          world, even our faith. 1 Jn 5:5 Who is he that overcometh the world,
          but he that believeth that Jesus is the Son of God. The above
          scriptures simply define what our theme is. But for the purpose of
          emphasis and general knowledge. Victory is an act of defeating an
          enemy or opponent in a battle, game or a competition. Race is a
          situation whereby individuals or groups compete to be first to achieve
          a particular objective. But in the context of the above theme we are
          talking about victorious race i.e. the victory of the Soul of man. We
          know that the spiritual controls the physical and the bible makes us
          to understand that God is a Spirit -John 4:24 and man is created in
          the image of God. -Genesis 1:26. These simply imply that man is a
          spirit i.e. God's likeness; but he has a soul and lives in a body. The
          soul of man is what makes him a spiritual being. Gen 2:7 And the LORD
          God formed man of the dust of the ground, and breathed into his
          nostrils the breath of life; and man became a living soul. God the
          Spirit created the world and made the man to have dominion and rule
          the earth -Genesis 1:28, but man chose to opt for Satan's advice of
          disobeying his creator.- Genesis 3:5 Man therefore surrendered his
          heritage of dominion of the world to Satan. See and hear Satan's
          attestation confirming that Man surrendered his heritage to satan in
          the the underlined statement in the bible text below: Luk 4:5 And the
          devil, taking him (Jesus) up into an high mountain, shewed unto him
          all the kingdoms of the world in a moment of time. Luke 4:6 And the
          devil said unto him, All this power will I give thee, and the glory of
          them: for that is delivered unto me; and to whomsoever I will I give
          it. Jesus Christ also makes us to understand that worldly success is
          vanity compare to success of the soul. - He said in Mark 8:36 For what
          shall it profit a man, if he shall gain the whole world, and lose his
          own soul? The opening bible text says whatsoever is born of God
          overcomes the world, meaning that every child of God defeats this evil
          world. 1Jn 5:5 Who is he that overcometh the world, but he that
          believeth that Jesus is the Son of God. The world is currently under
          the control of the devil and it takes those who are born again i.e.
          Those that have received Jesus into their lives, those that have
          confessed Him as their Lord and Saviour to overcome the world. See
          what the next text says: 2Corinthians 4:4 ...the god (Satan) of this
          world hath blinded the minds of them which believe not, lest the light
          of the glorious gospel of Christ, who is the image of God, should
          shine unto them. If we fail to retain the knowledge of God in us then
          we have failed woefully and therefore become vulnerable to Satan and
          his attack. What is the knowledge of God? Knowing the personality of
          God revealed through Jesus Christ His Son. 1Corinthians 1:24 ...Christ
          the power of God, and the wisdom of God. By Wisdom God made the earth
          and by understanding He established the heaven -Proverbs 3:18.
          Therefore to overcome the world and have a glorious and victorious
          race you need Jesus right now. Tomorrow may be too late. Time is the
          greatest enemy of mankind. Make haste while the sun shines. The moment
          of right decision is now!
        </p>
        <div>
          <p className="PastorCorner-callus">
            Please call us now on +44 (0) 1243860928 or +44(0)7872534283 or
            email: pastor@rccgtruevineparish.org.uk.
          </p>
          <p className="PastorCorner-summary">
            God Bless You, Your Brother<span> Gbenga Adeyefa-Ojomo</span>
          </p>
        </div>
      </section>
    </PageContent>
  );
}

export default PastorsCorners;
