import React, { useEffect, useState } from "react";
import "./PageContent.css";
import Navigation from "./Navigation";
import Banner from "./Banner";
import Footer from "./Footer";
import Sidebar from "./Sidebar";

function PageContent(props) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    document.title = props.pageTitle;
  }, []);
  return (
    <div className="PageContent">
      <nav>
        <Navigation toggleOpen={toggleOpen} />
        <Sidebar isOpen={isOpen} toggleOpen={toggleOpen} />
      </nav>
      <header>
        <Banner bgImage={props.bgImage} pageTitle={props.pageTitle} />
      </header>
      <section>
        <div className="PageContent-content">{props.children}</div>
      </section>
      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default PageContent;
