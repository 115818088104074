import React, { useState } from "react";
import "./Sidebar.css";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";

function Sidebar({ isOpen, toggleOpen }) {
  return (
    <aside className={`Sidebar ${isOpen ? "show-sidebar" : ""}`}>
      <button type="button" className="close-btn" onClick={toggleOpen}>
        <FaTimes />
      </button>

      <div className="Sidebar-container">
        <ul className={`${isOpen ? "Sidebar-links" : ""}`}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/pastors-corner">Pastor's corner</Link>
          </li>
          <li>
            <Link to="/departments">Our People</Link>
          </li>
          <li>
            <Link to="/announcements">Annoucements</Link>
          </li>
        </ul>
      </div>
    </aside>
  );
}

export default Sidebar;
