import React from "react";
import PageContent from "./PageContent";
import './Departments.css';


function Departments() {
  return (
    <PageContent pageTitle="Departments" bgImage="services">
      <section className="Departments">
        <div>
          <h4 className="Departments-heading">Choir </h4>
          <p>
            But ye are a chosen generation, a royal priesthood, an holy nation,
            a peculiar people; that ye should show forth the praises of him who
            hath called you out of darkness into his marvellous light. 1 Peter
            2: 9. We are a people of promise, called to kings and priest in His
            name; We are joint heir with Jesus, a chosen race to show forth His
            praise; By our Praise we send the demons fleeing, By our Praise we
            glorify His name,By our Praise, we sharpen each other sword, By our
            Praise, we will reach the world, Reaching the lost is our aim.
          </p>
        </div>
        <div>
          <h4 className="Departments-heading">Ushering</h4>
          <p>
            Let all things be done decently and in order (1 Cor 14:40). The
            Ushering Department is a key to orderliness in all church
            programmes. The first contact to welcome you to the presence of God
            when you're worshipping with us.
          </p>
          <p>Duties</p>
          <ul>
            <li>Meet, Greet and welcome people into service.</li>
            <li>Keeps order at church services.</li>
            <li>Maintain programme schedule and time management.</li>
            <li>Distribute bulletins and service programmes.</li>
            <li>Collect Tithes and Offerings.</li>
          </ul>
          <p>
            Do you want to serve God with your talents, cheerfulness and
            welcoming smile? Then the ushering department is the right place to
            be, God bless you as you determined to work for Him
          </p>
        </div>
        <div>
          <h4 className="Departments-heading">Evangelism & Outreach</h4>
          <p>
            The Vision of the Evangelism Department is to see every individual
            member of RCCG True Vine develops a compassion and has a burden for
            soul winning; Mark 16:15 “ Go into all the world and preach the
            gospel to every creature” We know that not everyone is called to be
            an evangelist, but all are called to witness. We go out regularly
            into the community to witness, as a group or 1-on-1.
          </p>
        </div>
        <div>
          <h4 className="Departments-heading">Media</h4>
          <p>
            The Media Ministry supports just about every aspect of the church’s
            ministries. A primary function is the support to the Worship
            Ministry. Team Operates and maintains the sound, projection
            equipment before, during, and after service. Sound checks and
            practices are done before the service. Microphones, speakers, sermon
            recordings e.t.c, are actively controlled and monitored during
            service. Team also projects visuals that include song and sermon
            texts as well as short videos that facilitate worship.
          </p>
        </div>
      </section>
    </PageContent>
  );
}

export default Departments;
