import React from "react";
import "./Banner.css";

function Banner(props) {
  const styles = {
    backgroundImage: `url(${require(`../images/${props.bgImage}.jpg`)})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "65vh",
    width: "100%",
    color: "white",
    marginBottom: "20px",
  };
  return (
    <section className="Banner" style={styles}>
        <h1 className="Banner-heading">{props.pageTitle}</h1>
{/* 
      <div style={styles}>
      </div>
      <div style={{backgroundColor:'black', height:'65vh'}}>
      </div> */}
    </section>
  );
}

export default Banner;
