import React from "react";
import PageContent from "./PageContent";

function Annoucements() {
  return (
    <PageContent pageTitle="Annoucements" bgImage="services">
      <p>
        African Mission Please, give hope to the less privilege and the
        forgotten ones on mission field by donating to African Mission (a
        Charity Organisation of RCCG worldwide). Please donate and pray.
        (Standing order mandate forms are available). Soup Kitchen (Caring For
        The Homeless In Our Community) Soup Kitchen is an outreach to the
        homeless in our community, Every 2nd Sunday, 2:30pm-4pm. Night of Prayer
        Night vigil: 25th September 2015, time : 10pm – 1am (Prayer Chain --
        RCCG TRUE Vine Parish) Prayer Chain -- RCCG TRUE Vine Parish prays for
        24hr without break. Every one follows their time slot. Women breakfast
        meeting – TBA. Perfected Praisers: Free Music Session for the children
        Every Friday @ 5pm -6:30 Evangelism - Saturday 12th September 2015 @
        12pm -2pm. Friendship Sunday – TBA. Church 4th year anniversary 6th &
        7th June 2015 (Refreshing Moment-6/6/15) @ 4pm You are cordially invited
        to the cross over night service tagged "A FRESH START" Date: Saturday,
        31st Dec 2016. Time: from 10pm Venue: RCCG TRUE VINE PARISH Janeses
        church Hall 45 Linden Road Bognor Regis PO21 2AP. Come, The Trinity in
        council will be waiting for you at Bethel. JESUS IS LORD.
      </p>
    </PageContent>
  );
}

export default Annoucements;
