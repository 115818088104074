import React, { useState, useEffect } from "react";
import "./LandingPage.css";
import Scriptures from "./Scriptures";
import logo from "../images/rccglogo-1.png";
import { Link } from "react-router-dom";
import Card from "./Card";

const LandingPage = () => {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  //   delay();
  // }, []);

  // function delay() {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 5000);
  // }

  return (
    <>
      <section className="Landing">
        <div className="Landing-content">
          <img src={logo} alt="Rccg Logo" />

          <h1 className="Landing-truevine">Welcome to True Vine Parish</h1>

          <Scriptures />

          <div className="Buttons-links">
            <Card heading="Join Zoom Meeting" hdColor="#3A86FF">
              <div className="LandingPage-card">
                  <p>Meeting ID: 401 758 5821</p>
                  <p>Password: 910506</p>
                  <p >Dial by your location</p>
                  <ul className="LandingPage-card-nos">
                    <li>++44 203 481 5237</li>
                    <li>++44 203 481 5240</li>
                  </ul>
                <a
                  href="https://zoom.us/j/4017585821?pwd=UXpCazdjbXdTYlB1b2xjTWo5ZjkzUT09"
                  target="_blank"
                >
                  <button
                    className="LandingPage-card-button"
                    style={{ backgroundColor: "#3A86FF", color: "white" }}
                  >
                    Join Meeting
                  </button>
                </a>
              </div>
            </Card>

            <Card color="pink" heading="True Vine Parish">
              <div className="LandingPage-card">
                <p>
                  TRUE VINE PARISH is an arm of RCCG commissioned by God to
                  preach the Gospel of our Lord Jesus Christ and to ....
                </p>
                <Link to="/about-us">
                  <button className="LandingPage-card-button btn-success">
                    VISIT
                  </button>
                </Link>
              </div>
            </Card>

            <Card hdColor="#01353C" heading="Give Online">
              <div className="LandingPage-card">
                <p>
                  Each of you should give what you have decided in your heart to
                  give, not reluctantly or under compulsion, for God loves a
                  cheerful giver."
                </p>
                <div className="Card-bibleverse">"2 Corinthians 9:7"</div>
                <a
                  href="https://portal.mydona.com/assets/webPay/index.php?cid=67"
                  target="_blank"
                >
                  <button className="LandingPage-card-button btn-info">
                    GIVE NOW
                  </button>
                </a>
              </div>
            </Card>
            {/* <Link to="/announcements" className="btn btn-success">
              <div className="item">Annoucements</div>
            </Link>
            <Link to="/about-us" className="btn btn-danger">
              <div className="item">True Vine Parish</div>
            </Link>
            <a
              className="btn btn-info"
              href="https://portal.mydona.com/assets/webPay/index.php?cid=67"
              target="_blank"
            >
              <div className="item">Give Online</div>
            </a> */}
          </div>
        </div>
      </section>
      <ul className="slideshow">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </>
  );
};

export default LandingPage;
