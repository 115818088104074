import React from "react";
import "./Navigation.css";
import { Link } from "react-router-dom";
import { FaAlignRight } from "react-icons/fa";

function Navigation({ toggleOpen }) {
  return (
    <>
      <nav className="Navigation">
        <div className="Navigation-header">
          <h2>True Vine Parish</h2>
          <button type="button" className="toggle-btn" onClick={toggleOpen}>
            <FaAlignRight></FaAlignRight>
          </button>
        </div>
        <ul className="Navigation-links">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about-us">About Us</Link>
          </li>
          <li>
            <Link to="/pastors-corner">Pastor's corner</Link>
          </li>
          <li>
            <Link to="/departments">Our People</Link>
          </li>
          <li>
            <Link to="/announcements">Annoucements</Link>
          </li>
          <li>
            <Link to="#">Contact Us</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}

export default Navigation;
