import React from "react";
import "./Card.css";

function Card(props) {
  return (
    <div
      className="Card"
      style={{
        backgroundColor: props.bgColor,
      }}
    >
      <div className="Card-heading" style={{backgroundColor:props.hdColor}}>
        <h3>{props.heading}</h3>
      </div>
      <div className="Card-content">{props.children}</div>
    </div>
  );
}

export default Card;
