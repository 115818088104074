import React from "react";
import "./About.css";
import PageContent from "./PageContent";

function About() {
  return (
    <PageContent pageTitle="About Us" bgImage="about-us">
      <section className="About">
        <p>
          The Redeemed Christian Church of God (RCCG) is a group of
          international churches established since 1952. TRUE VINE PARISH is an
          arm of RCCG commissioned by God to preach the Gospel of our Lord Jesus
          Christ and to raise followers (Christian) that will do the Will of
          God. Apart from the spiritual goal, True Vine Parish is also
          commissioned to meet the physical needs of the inhabitants of West
          sussex(Bognor Regis) and its environs regardless of past or present
          experiences and situations. If you are hungry to know more about God
          and fulfil destiny in Christ, True Vine Parish is the right place of
          worship for you and for everyone desiring to have a closer and deeper
          relationship with God through Jesus Christ. Join us at one of our
          services and you will be glad you have found the right family of God's
          worshippers.
        </p>
        <div>
          <h2>Our Statement of Faith</h2>
          <p>
            The following Statement of Faith are scriptural and within the
            context of the bible.
          </p>
          <ul>
            <li>
              We believe in God the Father, the Creator of Heaven and Earth.
            </li>
            <li>
              We believe in Jesus Christ, He is Son of God, who was crucified
              for the sins of all mankind and rose on the third day to sit at
              the right hand of God the Father.
            </li>
            <li>
              We believe in the Holy Spirit, who dwells in us and guides us
              according to the will of the Father.
            </li>
            <li>We believe in the Bible as the infallible Word of God.</li>
            <li>
              We believe in divine healing, the restoration of health to the one
              who believes and acts out God’s word, and that Jesus is the
              Healer.
            </li>
            <li>
              We believe in tithing and bringing offerings into the local
              church. We also believe in giving alms, caring for the poor, sick,
              homeless and otherwise unfortunate.
            </li>
            <li>
              We believe in water baptism in the name of the Father, Son and
              Holy Spirit.
            </li>
          </ul>
        </div>

        <div>
          <h2>RCCG Mandate</h2>
          <p>As Recieved By The General Overseer, Pastor E.A. Adeboye</p>
          <ul>
            <li>To Make Heaven.</li>
            <li>To take as many people with us.</li>
            <li>To have a member of RCCG in every family of all nations</li>
            <li>To accomplish No. 1 above, holiness will be our lifestyle.</li>
            <li>
              To accomplish No. 2 and 3 above, we will plant churches within
              five minutes walking distance in every city and town of developing
              countries and within five minutes driving distance in every city
              and town of developed countries.
            </li>
          </ul>
        </div>

        <p className="About-summary">
          ‘‘ We will pursue these objectives until every Nation in the world is
          reached for the Lord Jesus Christ.’’ <span>Pastor E.A. Adeboye</span>
        </p>
      </section>
    </PageContent>
  );
}

export default About;
