import React from "react";
import "./Footer.css";
import logo from "../images/rccglogo-1.png";

function Footer() {
  return (
    <footer className="Footer">
      <div className="Footer-details">
        <div>
          <h3>About True Vine</h3>
          <p>
            True Vine is a registered charity (1147716) in England. True Vine is
            a Parish of the Redeemed Christian Church of God Church of God
            located in Bognor Regis, West Sussex. If you need to speak to a
            counsellor urgently, please call +44 (0) 1243860928 +447947307714 or
            +447872534283
          </p>
        </div>

        <div>
          <h3>Find Us</h3>
          <p>
            Jeneses Church Hall (Former united Reformed Church) 45 Linden Road,
            Bognor Regis, West Sussex, PO21 2AP. © All rights reserved
          </p>
        </div>
      </div>

      <div className="Footer-image">
        {/* <h3>True Vine Parish</h3> */}
        <img src={logo} alt="RCCG LOGO" />
      </div>
    </footer>
  );
}

export default Footer;
