import React, { Component } from "react";
import "./Scriptures.css";

class Scriptures extends Component {
  static defaultProps = {
    scripturesArray: [
      {
        verse: "John 3:16",
        text:
          "For God so loved the world that he gave is only begotten son, that whosoever believeth in me should not perish but have everlasting life",
      },
      {
        verse: "Gen 1:1",
        text: "In the beginning God created the heaven and the earth",
      },
      {
        verse: "Jeremiah 29:13",
        text:
          "And you will seek Me and find Me, when you search for Me with all your heart.",
      },
      {
        verse: "Gen 1:3",
        text: "And God said, Let there be light: and there was light.",
      },
      {
        verse: "Psalm 27:1",
        text:
          "The Lord is my light and my salvation Whom shall I fear? The Lord is the strength of my life Of whom shall I be afraid?",
      },
    ],
  };

  state = {
    scriptures: this.props.scripturesArray[0],
  };

  componentDidMount() {
    const random = Math.floor(
      Math.random() * this.props.scripturesArray.length
    );
    const newScripture = this.props.scripturesArray[random];
    this.setState({ scriptures: newScripture });
  }

  render() {
    const { scriptures } = this.state;
    return (
      <div className="Scriptures">
        <h3 className="Scriptures-verse animated bounceInLeft">
          {scriptures.verse}
        </h3>
        <h4 className="Scriptures-text animated bounceInRight ">
          {scriptures.text}
        </h4>
      </div>
    );
  }
}

export default Scriptures;
